import { Service } from './DBService';

const PROD_API_BASE_URL = "https://www.lycomms.com/appv2/";
const TEST_API_BASE_URL = "https://test.lycomms.com/appv2/";
const STAGING_API_BASE_URL = "https://staging.lycomms.com/appv2/";
const DEV_API_BASE_URL = "http://localhost:8000/lycomms/appv2/";

class ApiService {

  constructor() {
    if (window.location.hostname === 'app.lycomms.com')
      this.api_url = PROD_API_BASE_URL;
    else if (window.location.hostname === 'app-test.lycomms.com')
      this.api_url = TEST_API_BASE_URL;
    else if (window.location.hostname === 'app-staging.lycomms.com')
      this.api_url = STAGING_API_BASE_URL;
    else
      this.api_url = DEV_API_BASE_URL;
  }

  async fetchData() {

    const requestOptions = {
      method: 'GET',
      credentials: 'include'
    };
    
    requestOptions.body = JSON.stringify(requestOptions.body);
    
    return await fetch(this.api_url + 'getdata.json', requestOptions)
    .then((response) => response.json())
    .catch(
      (err) => {
        console.error('fetchData => error');
        console.error(err);
      }
    );
  }

  async postData(batch) {console.log(batch)
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      credentials: 'include',
      body: JSON.stringify(batch) // Send the entire batch
    };
  
    try {
      const response = await fetch(this.api_url + 'processone.json', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
  
      // Assuming server returns an array of results for each item in the batch
      return data.results || [];
    } catch (error) {
      console.error('postData => batch failed:', error);
      return null; // Return null to signal an error
    }
  }

  async verifyAccessKey(accessKey) {
    
    if(accessKey) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: {
          "access_key": accessKey
        }
      };
      
      requestOptions.body = JSON.stringify(requestOptions.body);

      return await fetch(this.api_url + 'verifykey.json', requestOptions)
      .then((response) => response.json())
      .catch(
        (err) => {
          console.error('verifyAccessKey => ' + err);
        }
      );
    }
    
    return false;
  }

  async ping() {
    return await fetch(this.api_url + 'ping')
    .then((response) => response.json())
    .catch(
      (err) => {
        console.error(err);
      }
    );
  }
}

export default new ApiService();