//import { Helper } from './Helpers';

class CameraService {
    
  imgToBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      var img = new Image();

      img.src = event.target.result;
      
      img.onload = function(el) {
        var elem = document.createElement('canvas');
        var imgRatio = this.naturalHeight / this.naturalWidth;
        let longestSide = 1280;

        if (imgRatio < 1 ) { // landscape
          elem.width = longestSide;
          elem.height = Math.floor( longestSide * imgRatio );
        } else { // portrait
          elem.width = Math.floor( longestSide / imgRatio );
          elem.height = longestSide;
        }

        var ctx = elem.getContext('2d');
        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

        callback(elem.toDataURL('image/jpeg', 0.8));
      }
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
  };
}

export const Camera = new CameraService();